import { api } from '@/helpers/api'
import { commonUtils } from '@/helpers/commonUtils'
import { dayjsHelper } from '@/helpers/dayjsHelper'
import { envHelper } from '@/helpers/envHelper'
import { storageHelper } from '@/helpers/storageHelper'
import { computed, makeObservable, observable, runInAction } from 'mobx'
import { ee } from '../eventEmitter/eventEmitter'
import { translateService, TranslateServiceType } from '../translateService'
import { TranslateServiceConsts } from '../translateService/consts'
import { routerService } from '../routerService'
import { UserActionInfo } from '../../helpers/api/user'
import { LENOVO_WEBSTORE_URL, PAGE_LINKS, STAFF_QR } from '@/helpers/commonConsts'
declare let ExternalSLBSDK: any
type Locale = TranslateServiceType.Locale
type LocaleWithAutoDetect = TranslateServiceType.LocaleWithAutoDetect
// 提高PDF最大页数为200的用户白名单
const WHITE_LIST_FOR_200_MAX_PDF_PAGE = ['850316974', '596382741']
// 提高PDF最大页数为500的用户白名单
const WHITE_LIST_FOR_500_MAX_PDF_PAGE = ['534627089']
export enum UserVersion {
  FREE = 'free',
  TEMPORARY = 'temporary',
  VIP = 'vip',
}
type PaySuccessListener = () => void

class UserService {
  @observable userData: ServerDataTypes.UserInfo | null = null

  // pdf 相关配置
  @observable pdfSourceLang: LocaleWithAutoDetect = TranslateServiceConsts.AUTO_DETECT_LANG
  // eslint-disable-next-line max-len
  @observable pdfTargetLang: Locale = commonUtils.standardizeLocale(
    TranslateServiceConsts.DEFAULT_TRANSLATE_TARGET_LANG
  )

  // onlineTranslation 相关配置
  @observable onlineTranslationSourceLang: LocaleWithAutoDetect =
    TranslateServiceConsts.AUTO_DETECT_LANG
  // eslint-disable-next-line max-len
  @observable onlineTranslationTargetLang: Locale = commonUtils.standardizeLocale(
    TranslateServiceConsts.DEFAULT_TRANSLATE_TARGET_LANG
  )

  @observable canScroll = false
  @observable dragPdfModal = false
  @observable initialed = false

  @observable version = '1.3.11'
  @observable updateDate = '2023-12-12'
  @observable downloadUrl = 'https://cdn.yiban.io/extensions/会译·对照式翻译_1_3_11.zip'
  @observable hy360DownloadUrl: string =
    'https://download.chrome.360.cn/ext/prod-dgeiaiglmhdhajbpfbmajaajdlfdinpi-f4744b86aadc5454004c56566d9ab927.crx'

  @observable selectedPdfFromHome: { hash: string; file: File } | null = null
  // 在购买pro,pro+ 的弹窗支付成功，并且关闭支付成功弹窗时，触发的监听
  paySuccessfulListener: PaySuccessListener[] = []

  @observable localeList: TranslateServiceType.LocaleList = []
  // 图片翻译配置
  @observable imgSourceLang: TranslateServiceType.LocaleWithAutoDetect =
    TranslateServiceConsts.AUTO_DETECT_LANG
  @observable imgTargetLang: TranslateServiceType.Locale = commonUtils.standardizeLocale(
    TranslateServiceConsts.DEFAULT_TRANSLATE_TARGET_LANG
  )
  // 在线翻译配置
  @observable onlineSourceLang: TranslateServiceType.LocaleWithAutoDetect =
    TranslateServiceConsts.AUTO_DETECT_LANG
  @observable onlineTargetLang: TranslateServiceType.Locale = commonUtils.standardizeLocale(
    TranslateServiceConsts.DEFAULT_TRANSLATE_TARGET_LANG
  )
  @observable translateCountMap: {
    [key: string]: { count: number; mode: 1 | 2; useCount: number }
  } | null = null

  // 用户手动关闭购买pro,pro+弹窗的监听
  closePayModalListener: (() => void) | undefined = undefined

  // 侧边栏的翻译配置
  @observable realtimeSourceLang: TranslateServiceType.LocaleWithAutoDetect =
    TranslateServiceConsts.AUTO_DETECT_LANG
  @observable realtimeTargetLang: TranslateServiceType.Locale = commonUtils.standardizeLocale(
    TranslateServiceConsts.DEFAULT_TRANSLATE_TARGET_LANG
  )

  @observable enableEduActivity = false

  @observable staffQrUrl = STAFF_QR

  constructor() {
    makeObservable(this)
    this.init()
  }
  @computed get userAccount() {
    return this.userData?.lenovoAccount || this.userData?.inviteCode || ''
  }

  @computed get userId() {
    return this.userData?.inviteCode || this.userData?.lenovoAccount || ''
  }

  @computed get isVip() {
    return this.vipVersion === UserVersion.VIP
  }
  @computed get remainNormalTranslateCount() {
    if (this.vipVersion === UserVersion.VIP) {
      return 1
    }
    if (!this.translateCountMap) {
      return 0
    }
    return this.translateCountMap['normal'].useCount || 0
  }

  @computed get remainSearchTranslateCount() {
    if (this.vipVersion === UserVersion.VIP) {
      return 1
    }
    if (!this.translateCountMap) {
      return 0
    }
    return this.translateCountMap['search'].useCount || 0
  }

  @computed get remainSelectTranslateCount() {
    if (this.vipVersion === UserVersion.VIP) {
      return 1
    }
    if (!this.translateCountMap) {
      return 0
    }
    return this.translateCountMap['select'].useCount || 0
  }

  @computed get remainHoverTranslateCount() {
    if (this.vipVersion === UserVersion.VIP) {
      return 1
    }
    if (!this.translateCountMap) {
      return 0
    }
    return this.translateCountMap['hover'].useCount || 0
  }

  @computed get remainPictureTranslateCount() {
    if (this.vipVersion === UserVersion.VIP) {
      return 1
    }
    if (!this.translateCountMap) {
      return 0
    }
    return this.translateCountMap['picture'].useCount || 0
  }

  @computed get remainScreenshotTranslateCount() {
    if (this.vipVersion === UserVersion.VIP) {
      return 1
    }
    if (!this.translateCountMap) {
      return 0
    }
    return this.translateCountMap['screenshot'].useCount || 0
  }

  @computed get remainOnlineTranslateCount() {
    if (this.vipVersion === UserVersion.VIP) {
      return 1
    }
    if (!this.translateCountMap) {
      return 0
    }
    return this.translateCountMap['online'].useCount || 0
  }

  @computed get remainVideoTranslateCount() {
    if (this.vipVersion === UserVersion.VIP) {
      return 1
    }
    if (!this.translateCountMap) {
      return 0
    }
    return this.translateCountMap['video'].useCount || 0
  }

  @computed get remainRealTimeTranslateCount() {
    if (this.vipVersion === UserVersion.VIP) {
      return 1
    }
    if (!this.translateCountMap) {
      return 0
    }
    return this.translateCountMap['real_time'].useCount || 0
  }

  @computed get remainStudyTranslateCount() {
    if (this.vipVersion === UserVersion.VIP) {
      return 1
    }
    if (!this.translateCountMap) {
      return 0
    }
    return this.translateCountMap['study'].useCount || 0
  }

  @computed get dragTipVisibility() {
    return this.canScroll && this.dragPdfModal
  }

  @computed get isLogin() {
    return !!this.userData && this.userData.versionData.version !== 'temporary'
  }

  @computed get vipExpiredTime() {
    if (!this.userData || this.vipVersion !== UserVersion.VIP) {
      return 0
    }

    const {
      versionData: { endTime },
    } = this.userData
    return endTime
  }

  @computed get vipVersion() {
    if (!this.userData) {
      return UserVersion.TEMPORARY
    }
    return this.userData.versionData.version
  }

  @computed get maxPDFPage() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { version },
      inviteCode,
    } = this.userData!
    if (version === 'free') {
      return 20
    }
    if (WHITE_LIST_FOR_500_MAX_PDF_PAGE.includes(inviteCode)) {
      return 500
    }
    if (WHITE_LIST_FOR_200_MAX_PDF_PAGE.includes(inviteCode)) {
      return 200
    }
    return 1000
  }

  @computed get totalPdfUploadCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { chatPdfUploadTotalCount },
    } = this.userData!
    return chatPdfUploadTotalCount
  }

  @computed get totalChatCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { chatPdfChatTotalCount },
    } = this.userData!
    return chatPdfChatTotalCount
  }

  @computed get remainPdfUploadCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { chatPdfUploadRemainCount },
    } = this.userData!
    return chatPdfUploadRemainCount
  }

  @computed get remainPdfChatCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { chatPdfChatRemainCount },
    } = this.userData!
    return chatPdfChatRemainCount
  }

  // 翻译 token 剩余次数
  @computed get remainTranslateCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { version, freeAiTranslateCount, proAiTranslateCount, tokenBoosterPackageCount },
    } = this.userData!
    if (version === 'free') {
      return freeAiTranslateCount
    }
    return proAiTranslateCount + tokenBoosterPackageCount
  }

  @computed get totalTranslateCount() {
    if (!this.isLogin) {
      return 0
    }
    const {
      versionData: { version, totalFreeAiTranslateCount, totalProAiTranslateCount },
    } = this.userData!
    if (version === 'free') {
      return totalFreeAiTranslateCount
    }
    return totalProAiTranslateCount
  }

  @computed get sourceLanguageOptions() {
    return [
      {
        locale: TranslateServiceConsts.AUTO_DETECT_LANG,
        localName: '自动检测',
        name: '自动检测',
      },
    ]
      .concat(this.localeList)
      .map((item) => {
        return {
          label: item.localName,
          value: item.locale,
        }
      })
  }

  @computed get targetLanguageOptions() {
    return [...this.localeList].map(({ locale, localName }) => {
      return {
        label: localName,
        value: locale,
      }
    })
  }

  public async init() {
    this.initStorageData()
    this.syncLenovoLoginStatus()
    this.listenLoginEvent()
    await this.updateUserInfo()
    this.updateVersionInfo()
    this.initLocaleList()
    this.updateEnableEduActivity()
  }

  public setPdfLang(type: 'source' | 'target', lang: Locale) {
    if (type === 'source') {
      this.pdfSourceLang = lang
      storageHelper.set({ pdfSourceLang: lang })
    } else {
      this.pdfTargetLang = lang
      storageHelper.set({ pdfTargetLang: lang })
    }
  }

  public setImgLang(type: 'source' | 'target', lang: Locale) {
    if (type === 'source') {
      this.imgSourceLang = lang
      storageHelper.set({ imgSourceLang: lang })
    } else {
      this.imgTargetLang = lang
      storageHelper.set({ imgTargetLang: lang })
    }
  }

  public setOnlineLang(type: 'source' | 'target', lang: Locale) {
    if (type === 'source') {
      this.onlineSourceLang = lang
      storageHelper.set({ onlineSourceLang: lang })
    } else {
      this.onlineTargetLang = lang
      storageHelper.set({ onlineTargetLang: lang })
    }
  }

  /** 主动(手动)登出的情况 */
  public async doLogout() {
    try {
      await api.user.logout()
      localStorage.setItem('lastToken', '')
    } catch (error) {}
    this.updateUserInfo()
  }

  public async updateUserInfo() {
    try {
      const { userData } = await api.user.getUserInfo()
      console.log('get user info: ', userData)
      runInAction(() => {
        this.userData = userData
      })
      this.updateUserTranslateCount()
    } catch (error) {
      runInAction(() => {
        this.userData = null
      })
      // storageHelper.set({ userData: null })
    }
    runInAction(() => {
      this.initialed = true
    })
    ee.emit('userServiceInitialed', {})
    this.updateEnableEduActivity()
  }

  public openPricingTab(type: 'buy' | 'upgrade' | 'renew' | 'wordPack' | 'jump', from: string) {
    if (type === 'jump') {
      window.open(`/pricing?from=${from}`, '_blank')
      return
    }
    const url = `/pricing?type=${type}&from=${from}&v=${
      this.vipVersion === 'free' ? 'pro' : 'pro+'
    }`
    window.open(url, '_blank')
  }

  public async openWebOnlineTranslate(tab: 'online' | 'img' = 'online') {
    const url = 'translation'

    const urlWithParams = commonUtils.genUrl(`${envHelper.webHost}/${url}`, {
      tab,
    })

    window.open(urlWithParams, '_blank')
  }

  public openOptionPage() {
    if (commonUtils.isInstalledExtension()) {
      commonUtils.sendExtensionMessage('openOptionPage', {})
    } else {
      window.open(LENOVO_WEBSTORE_URL, '_blank')
    }
  }

  public openEducationPage() {
    window.open(`${PAGE_LINKS.education_incentives}`, '_blank')
  }

  public trackUserRecord(actionType: string) {
    const token = commonUtils.genVisitorTokenStr()
    try {
      api.user.postVisitorRecord({ token, actionType })
    } catch (error) {
      //do nothing
    }
  }

  public postLenovoRecord(data: UserActionInfo) {
    const _data = {
      ...data,
      clientType: 'web',
    }
    try {
      api.user.postLenovoRecord(_data)
    } catch (error) {}
  }

  private async updateVersionInfo() {
    try {
      const { chromeVersion, hy360DownloadUrl, extensionUpdateTime, staffQrUrl } =
        await api.getHuiyiUrlConfig()
      this.staffQrUrl = staffQrUrl
      this.version = chromeVersion
      this.hy360DownloadUrl = hy360DownloadUrl
      const updateDate = dayjsHelper.formatTime(extensionUpdateTime * 1000, 'YYYY-MM-DD')
      this.updateDate = updateDate

      let hyChromeDownloadUrl = ''
      if (chromeVersion) {
        hyChromeDownloadUrl = `https://cdn.yiban.io/extensions/会译·对照式翻译_${chromeVersion.replace(
          /\./g,
          '_'
        )}.zip`
      }

      this.downloadUrl = hyChromeDownloadUrl
      console.log('version info', {
        chromeVersion,
        hyDownloadUrl: hyChromeDownloadUrl,
        hy360DownloadUrl,
        updateDate,
      })
      ee.emit('versionInfoChanged', {
        downloadUrl: hyChromeDownloadUrl,
        updateDate,
        hy360DownloadUrl,
        version: chromeVersion,
      })
    } catch (error) {
      // do nothing
    }
  }

  public updateSEMKey() {
    try {
      const { utm, kword, utm_term, bd_vid, bd_url, qhclickid, referer } = commonUtils.getUrlArgs()
      if (utm) {
        storageHelper.set({ utm })
      }
      if (
        document.referrer &&
        document.referrer.indexOf('ad.huiyi') === -1 &&
        document.referrer.indexOf('localhost') === -1
      ) {
        if (!storageHelper.get(['referer']).referer) {
          storageHelper.set({ referer: document.referrer })
        }
      }
      if (referer) {
        if (!storageHelper.get(['referer']).referer) {
          storageHelper.set({ referer: referer })
        }
      }
      if (kword) {
        const decodedKword = decodeFromGb2312(kword)
        storageHelper.set({ kword: decodedKword })
      }

      const storageReferer = storageHelper.get(['referer']).referer || ''
      if (storageReferer) {
        const refererChannel = encodeURIComponent(new URL(storageReferer).hostname)
        // www.so.com
        const { q } = commonUtils.getUrlArgs(storageReferer)
        if (refererChannel === 'www.so.com' && q) {
          storageHelper.set({ kword: q })
        }
        // cn.bing.com
        if (refererChannel === 'cn.bing.com' && utm_term) {
          storageHelper.set({ kword: utm_term })
        }
      }

      // 360 ocpc 投放参数
      if (qhclickid) {
        storageHelper.set({ qhclickid: qhclickid })
      }

      // 并不意味着 location.href 中有 bd_vid 就是从百度投放来的
      // 还有可能是从自定义投放页面来的 此时会携带 bd_url
      if (bd_vid || bd_url) {
        // 优先 bd_url 其次 location.href
        if (bd_url) {
          const bd_url_vid = commonUtils.getQueryFromUrl('bd_vid', bd_url)
          if (bd_url_vid) {
            storageHelper.set({ bdUrl: bd_url, bdVid: bd_url_vid })
            return
          }
        }
        storageHelper.set({ bdUrl: window.location.href, bdVid: bd_vid })
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  public addPaySuccessfulListener(listener: PaySuccessListener) {
    this.paySuccessfulListener.push(listener)
  }

  public removePaySuccessfulListener(listener: PaySuccessListener) {
    const index = this.paySuccessfulListener.findIndex((item) => item === listener)
    if (index !== -1) {
      this.paySuccessfulListener.splice(index, 1)
    }
  }

  public invokePaySuccessfulListener() {
    this.paySuccessfulListener.forEach((listener) => {
      listener()
    })
  }

  public openLearnPage(target: 'unlearned' | 'grasp') {
    const url = `${envHelper.webHost}/learning?tab=${target}`
    window.open(url, '_blank')
  }

  public async openPdfPage(fileUrl = '', tab: 'pdf' | 'favorite' = 'pdf') {
    const url = 'webapp/pdf'

    const urlWithParams = commonUtils.genUrl(`${envHelper.webHost}/${url}`, {
      fileUrl,
      tab,
    })

    window.open(urlWithParams, '_blank')
  }

  private initLocaleList() {
    translateService.getLocaleList().then((data) => {
      this.localeList = data
    })
  }

  private initStorageData() {
    const {
      dragPdfModal,
      pdfSourceLang,
      pdfTargetLang,
      imgSourceLang,
      imgTargetLang,
      onlineSourceLang,
      onlineTargetLang,
    } = storageHelper.get([
      'pdfSourceLang',
      'pdfTargetLang',
      'dragPdfModal',
      'imgSourceLang',
      'imgTargetLang',
      'onlineSourceLang',
      'onlineTargetLang',
    ])
    const getSourceLang = (lang: TranslateServiceType.LocaleWithAutoDetect) => {
      if (lang === 'auto-detect') {
        return TranslateServiceConsts.AUTO_DETECT_LANG
      }
      return lang
    }
    pdfSourceLang && (this.pdfSourceLang = getSourceLang(pdfSourceLang))
    pdfTargetLang && (this.pdfTargetLang = pdfTargetLang)
    imgSourceLang && (this.imgSourceLang = getSourceLang(imgSourceLang))
    imgTargetLang && (this.imgTargetLang = imgTargetLang)
    onlineSourceLang && (this.onlineSourceLang = getSourceLang(onlineSourceLang))
    onlineTargetLang && (this.onlineTargetLang = onlineTargetLang)
    this.dragPdfModal = dragPdfModal ?? true
  }

  // 记录学习模式埋点
  public recordLearningMode(
    learningActionType:
      | 'click_header_learning'
      | 'click_desc_learning'
      | 'click_banner_learning'
      | 'click_learning_page_upgrade'
  ) {
    this.trackUserRecord(learningActionType)
  }

  public doPay(data: { wordPack?: boolean; version?: ''; source: string }) {
    window.open(`${envHelper.webHost}/pricing?source=${data.source}`, '_blank')
  }

  public async setRealtimeLang(type: 'source' | 'target', lang: TranslateServiceType.Locale) {
    if (type === 'source') {
      this.realtimeSourceLang = lang
    } else {
      this.realtimeTargetLang = lang
    }
  }
  public async updateUserTranslateCount(useType?: PostDataTypes.UseType) {
    try {
      if (this.vipVersion === 'vip') {
        return
      }
      const {
        data: { use_data: useData, base_data: baseData },
      } = await api.user.getUserTranslateCount(useType)
      const data: { [key: string]: { count: number; mode: 1 | 2; useCount: number } } = {} as any
      if (!useType) {
        const baseDataKeys = Object.keys(baseData)
        for (const k of baseDataKeys) {
          data[k] = {
            count: baseData[k].count,
            mode: baseData[k].mode,
            useCount: useData[k].count > 0 ? useData[k].count : 0,
          }
        }
        this.translateCountMap = data
      } else {
        const translateCountMap = this.translateCountMap
        if (translateCountMap) {
          translateCountMap[useType].useCount =
            useData[useType].count > 0 ? useData[useType].count : 0
          this.translateCountMap = { ...translateCountMap }
        }
      }
    } catch (error) {}
  }

  public async updateEnableEduActivity() {
    if (this.vipVersion === 'temporary') {
      this.enableEduActivity = false
      return
    }
    try {
      const { match } = await api.user.checkEnableEduActivity()
      this.enableEduActivity = match && !this.isVip
    } catch (error) {
      this.enableEduActivity = false
    }
  }

  public async logoutBySLBSDK() {
    ExternalSLBSDK.nativeCall('SLLIDLogout')
  }

  public async doLogin(token: string, tryCount = 0) {
    // 如果已经安装了插件还是优先同步插件登录态
    try {
      if ((!commonUtils.isInstalledExtension() || tryCount > 1) && tryCount < 5) {
        await api.user.doLogin(token)
        console.log('doLogin userService', token)
      }
      await this.updateUserInfo()
      if (!this.isLogin && tryCount < 5) {
        setTimeout(() => {
          this.doLogin(token, tryCount + 1)
        }, 200)
        return
      }
      this.updateUserTranslateCount()
      this.updateEnableEduActivity()
    } catch (error) {
      console.log(error, 'error')
    }
  }
  /**
   * 调起登录框登录
   */
  public async loginBySLBSDK() {
    ExternalSLBSDK.nativeCall('SLLIDLogin')
  }

  public listenLoginEvent() {
    /**
     * 监听登录状态变化
     * @param {(data: {
     *   action: string, // login:登录, logout:登出
     *   token?: string, // 登录用户token
     *   realm?: string // 登录用户域
     * }) => void} callback
     */
    console.log(ExternalSLBSDK, 'ExternalSLBSDK add listener')
    ExternalSLBSDK.addListener(
      'LIDNotify',
      (data: { action: string; token?: string; realm?: string }) => {
        console.log(data, 'login status')
        // window.location.reload()
        if (data.action === 'login') {
          localStorage.setItem('lastToken', data.token || '')
          this.doLogin(data.token!)
        } else {
          this.doLogout()
        }
      }
    )
  }

  private syncLenovoLoginStatus() {
    /**
     * 获取用户信息
     * @returns {Promise<{
     *   status: number, // 登录状态，0未登录，1已登录
     *   token?: string, // 登录用户token
     *   realm?: string // 登录用户域
     * }>}
     */
    console.log('sync login status')
    ExternalSLBSDK.nativeCall('SLGetLoginStatus')
      .then(async (res: { status: 0 | 1; token: string; realm: string }) => {
        console.log('get login status', res)
        if (res.status === 1) {
          await this.doLogin(res.token)
          localStorage.setItem('lastToken', res.token || '')
        } else {
          // 未登录
          await this.doLogout()
        }
      })
      .catch(() => {
        this.doLogout()
      })
  }
}

export const userService = new UserService()
